 const types=[
    {extension:'png',width:100,icon:'file-image'},
    {extension:'jpg',width:100,icon:'file-image'},
    {extension:'jpeg',width:100,icon:'file-image'},
    {extension:'xls',width:100,icon:'file-excel'},
    {extension:'xlsx',width:100,icon:'file-excel'},
    {extension:'txt',width:100,icon:'file-text'},
    {extension:'pdf',width:100,icon:'file-pdf'},
    {extension:'ppt',width:100,icon:'file-ppt'},
    {extension:'pptx',width:100,icon:'file-ppt'},
    {extension:'doc',width:100,icon:'file-word'},
    {extension:'docx',width:100,icon:'file-word'},
    {extension:'other',width:100,icon:'file'},
]
export default types
