<template>
    <div>
        <a-card>
            <a :href="path" target="_blank" style="display:flex;">
                <div v-if="extension=='png'||extension=='jpg'||extension=='jpeg'">
                    <img style="max-width:80px;" :src="path" :alt="name">
                    {{name}}
                </div>
                <div v-else style="width:80px;height:auto;">
                    <a-icon :type="fileObj.icon" />
                    <span> {{name}}</span>
                </div>
            </a>
        </a-card>
    </div>
</template>

<script>
import filetypes from './filetype'
export default {
    props: { extension: String, path: String, name: String },
    data() {
        return {
            filetypes,
        }
    },
    computed: {
        fileObj: function () {
            console.log(this.filetypes)
            let res = this.filetypes.filter(item => item.extension == this.extension)
            console.log(res)
            if (res.length == 0) {
                //最后一个为通用类型
                return this.filetypes[this.filetypes.length]
            } else {
                return res[0]
            }
        }

    }
}
</script>

<style lang="scss" scoped>

</style>