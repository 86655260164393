<template>
  <div :key="randomKey">
    <a-upload-dragger name="file" :multiple="true" :headers="headers"
     :action="action" :remove="remove"
      @change="handleChange"
       :defaultFileList="fileList">
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox" />
      </p>
      <p class="ant-upload-text">
        单击或者拖拽上传
      </p>
      <p class="ant-upload-hint">
        支持单个或批量上传。
      </p>
    </a-upload-dragger>
  </div>

</template>
<script>
export default {
  props: {
    attachments: { type: Array },
  },
  data() {
    return {
      fileUrl: this.value,
      randomKey: Math.random(),
      headers: {
        Authorization: `Bearer ` + window.localStorage.getItem('token'),
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      fileList: [],
    };
  },
  mounted() {
    //获取字段名
    if (this.attachments !== null && Array.isArray(this.images) && this.images.length > 0) {
      // 构造一个images数组对象
      this.fileList = this.attachments.map(attm => {
        return {
          // 随机整数
          uid: Math.random(),
          id: attm.id,
          name: attm.path.split('/')[image.split('/').length - 1],
          status: 'done',
          url: attm.path,
        }
      })
      // this.fileList = this.images
    }
  },
  computed: {
    action() {
      return `/api/upload/image`
    },
  },
  methods: {
    handleChange(info) {
      const status = info.file.status;
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        this.$message.success(`${info.file.name} 上传成功！`);
        console.log("handleChange--done将info.fileList赋值给this.fileList", info.fileList);
        this.fileList = info.fileList;
        this.$emit('uploadFile', this.fileList)
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    clearFiles() {
      console.log('子组件清理文件')
      this.randomKey = Math.random()
    },
    remove(e) {
      //删除时返回被删除的对象，改对象为fileList中的数据
      console.log("remove方法，从组件中的fileList中删除被删除对象，再提交到父级")
      this.fileList.pop(e)
      this.$emit('uploadFile', this.fileList)
    }
  },
};
</script>
